<template>
  <main>
    <section id="section__primary" :class="listingView">
      <article>
        <h2 v-if="listingCategory === 'active'">Active Listings</h2>
        <h2 v-if="listingCategory === 'sold'">Current Show Home </h2>
        <p v-if="listingCategory === 'active'">Whether you’re looking to purchase your first home, “stepping up a level”, or acquiring your “dream home”, Stewart can make it happen. Here are his current listings of homes on the market...</p>
        <!-- <p v-if="listingCategory === 'sold'">Whether you’re looking to purchase your first home, “stepping up a level”, or acquiring your “dream home”, Stewart can make it happen. Here are the most recent homes that I have sold...</p> -->
        <p v-if="listingCategory === 'sold'">&nbsp;</p>
      </article>
      <ListingRack 
        :prp_classes="rackClass"
        :prp_ofCategory="listingCategory" 
      />
    </section>
  </main>
</template>

<script>
import { defineComponent, ref, watch, computed, onMounted } from 'vue';
import ListingRack from './Listing__Rack.vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'Page__Listings',
  components: { ListingRack },
  setup() {
    const listingCategory = ref('');
    const viewportWidth = ref(window.innerWidth);
    const listingView = ref('');
    const route = useRoute();

    const setListingCategory = () => {
      if (route.path === '/homes-ive-sold') {
        listingCategory.value = 'sold';
      }
      if (route.path === '/active-listings') {
        listingCategory.value = 'active';
      }
    };

    const handleResize = () => {
      viewportWidth.value = window.innerWidth;
    };

    watch(listingCategory, () => {});

    watch(route, (to) => {
      if (to.path === '/homes-ive-sold') {
        listingCategory.value = 'sold';
      }
      if (to.path === '/active-listings') {
        listingCategory.value = 'active';
      }
    });

    watch(viewportWidth, () => {
      if (viewportWidth.value > 1200) {
        listingView.value = 'grid_view';
      } else {
        listingView.value = 'card_view';
      }
    });

    onMounted(() => {
      setListingCategory();
      window.addEventListener('resize', handleResize);
    });

    const rackClass = computed(() => {
      if (viewportWidth.value > 1200) {
        return 'grid_view';
      } else {
        return 'card_view';
      }
    });

    return {
      listingCategory,
      listingView,
      rackClass,
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/scss/_global.scss';
  
  section#section__primary {
    background: $color__background--light;
    color: 666;    
  }

</style>
