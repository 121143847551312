//import Vue from 'vue' // Vue 2
import { createRouter, createWebHistory } from 'vue-router' // Vue 3
import VueRouter from 'vue-router'
import FrontPage from "@/components/Page__Front.vue"
import Listings from "@/components/Page__Listings.vue"
import Listing from "@/components/Page__Listing.vue"
import AboutPage from '@/components/Page__About.vue'
import NewHomeSales from '@/components/Page__Sales.vue'
import ContactPage from '@/components/Page__Contact.vue'
import Testimonials from '@/components/Page__Testimonials.vue'

// Transpiler for older browsers
import 'core-js/stable';
// optional but required for transforming generator fns.
import 'regenerator-runtime/runtime'; 

// /stewartelston/about-mecontact/
// /stewartelston/active-listings/
// /stewartelston/homes-ive-sold/
// /stewartelston/new-home-sales/
// /stewartelston/testimonials/

const routes = [
  {
    path: "/",
    name: "Home",
    component: FrontPage,
  },
  {
    path: "/home",
    name: "FrontPage",
    component: FrontPage,
  },
  {
    path: "/about",
    name: "About",
    component: AboutPage,
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactPage,
  },
  {
    path: "/new-home-sales",
    name: "NewHomeSales",
    component: NewHomeSales,
  },
  {
    path: "/testimonials",
    name: "Testimonials",
    component: Testimonials,
  },
  {
    path: "/:post_slug", //1168
    name: "Listings",
    component: Listings,
  },
  {
    path: "/listing/:post_id", //1168
    name: "Listing",
    component: Listing, 
    props: true,
  }
];

//Vue.use(VueRouter) // Vue 2

// const router = createRouter({
//   history: createWebHistory(),
//   routes,
// });

// // old vue 2 way
// const router = new VueRouter({
//   mode: 'history',
//   linkExactActiveClass: 'is-active',
//   routes // short for `routes: routes`
// })

// new Vue 3 way
const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: 'is-active',
  routes,
})

export default router;