<template>
  <main>
    <section id="section__primary--testimonials">
        <Loading_Spinner v-if="loading" class="light" />
        <article>
            <div class="testimonials_description">
                <h2>Testimonials</h2>
                <div class="page_content" v-html="pageContent.content"></div>
            </div>
        </article>
    </section>
  </main>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import Loading_Spinner from './Loading_Spinner';

const gql_query = gql`
  query {
    page(id: "cG9zdDoxMjM0") {
      content
    }
  }
`;

export default defineComponent({
  name: 'Page__Testimonials',
  components: {
    Loading_Spinner,
  },
  setup() {
    const loading = ref(true);
    const pageContent = ref([]);

    const { result } = useQuery(gql_query);
    watch(
      () => result.value,
      (data) => {
        if (data) {
          pageContent.value = data.page;
          loading.value = false;
        }
      },
      { immediate: true }
    );

    return {
      loading,
      pageContent,
    };
  },
});
</script>

<style lang="scss" >
  @import '@/scss/_global.scss';
  section#section__primary--testimonials { 
    background: $color__background--dark; 
    color: white;

    article {
      padding-bottom: 0;
    }

    .testimonials-description {
        margin-bottom: 65px;
    }
    figure {
        margin: 0 -65px;
        padding: 65px;
    }

    figure:nth-child(even) {
        background-color: $color__background--light;
        color: $color__font--dark;
    }
  }
</style>