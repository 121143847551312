<template>
  <div class="listing_rack" :class="listing_classes">
    <div class="load_icon_container" v-if="listings_loading"><Loading_Spinner style="left: 50%" /></div>
    <ul class="listing__rack">
        <p class="listings__none" v-if="noCurrentListings"><i>No listings to display at this time.</i></p>
        <li v-for="item in listings" :key="item.node.postId">
          <router-link :to="{ name: 'Listing', params: { post_id: item.node.postId } }">
          <transition name="fade" appear>
            <Listing 
              :prp_home_address="item.node.listings.homeaddress"
              :prp_price=item.node.listings.price
              :prp_square_feet=item.node.listings.squarefeet
              :prp_other_data="item.node.listings.otherdata"
              :prp_image_path="item.node.featuredImage.node.sourceUrl"
              :prp_title="item.node.title"
              :prp_classes="listing_classes"
              :prp_in_category="item.node.categories.nodes[0].name"
            />
            </transition>
          </router-link>
        </li>
      </ul>
      <Paginator 
        v-if="needsPagination" 
        :posts="posts" 
        :postsQuery="gql_query" 
        @updatePosts="handlePosts" 
        @updateLoading="val => updateLoading(val)"
        :limit="5" 
        where="sold" 
      />
  </div>
</template>

<script>
import { defineComponent, ref, watch, computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag'
import Loading_Spinner from './Loading_Spinner.vue'
import Listing from './Listing.vue'
import Paginator from './Paginate.vue';

const gql_query = gql`
    query GetAllPosts ($first: Int, $last: Int, $endCursor: String, $startCursor: String, $where: String) {
        posts(first:$first, last:$last, after:$endCursor, before:$startCursor, where:{categoryName: $where}) {
        pageInfo {
          startCursor
          hasPreviousPage
          hasNextPage
          endCursor
        }
        edges {
          node {
            listings {
              condofee
              fieldGroupName
              homeaddress
              otherdata
              price
            }
            postId
            featuredImage {
              node {
                sourceUrl
              }
            }
            title
            categories {
              nodes {
                categoryId
                name
              }
            }
          }
        }
      }
    }
   `

export default defineComponent({
  name: 'listing__rack',
  props: {
    prp_ofCategory: {
      default: "sold",
      type: String,
    },
      prp_classes: String
  },
  components: {
    Loading_Spinner,
    Listing,
    Paginator
  },
  setup(props) {
    const listings_loading = ref(true);
    const posts = ref([]);
    const listings = ref([]);
    const needsPagination = ref(false);
    const ofCategory = ref(props.prp_ofCategory);
    const hasNextPage = ref(false);
    const endCursor = ref(null);

    const runQuery = () => {
      const { prp_ofCategory } = props;

      if(prp_ofCategory === "sold" || prp_ofCategory === "active"){
      
        const { result, loading, error, refetch } = useQuery(
          gql_query,
          {
            first: 10,
            last: null,
            where: prp_ofCategory,
          },
          { fetchPolicy: 'network-only' }
        );

        watch(()=>result.value, () => {
          if (error.value) {
            console.error('ERROR: there was an error with the listings query..')
          } else if (!loading.value && result.value && result.value.posts) {
            handlePosts(result.value);
          }
        });
      } else {
        // category is invalid, query failed.
        console.error('ERROR: category is invalid, query failed..')
      }
    };

    const handlePosts = (data) => {
      if (data) {
        needsPagination.value = true;
        let newPosts = data.posts.edges;
        posts.value = data.posts;
        if (Array.isArray(newPosts)) {
          const updatedPosts = newPosts.map((edge) => {
            if (edge.node.featuredImage === null) {
              return {
                ...edge,
                node: {
                  ...edge.node,
                  featuredImage: {
                    node: {
                      sourceUrl:
                        'https://stewartelston.dreamhosters.com/wp-content/uploads/2021/06/no-house-image.jpg',
                    },
                  },
                },
              };
            }
            return edge;
          });
          listings.value = [...updatedPosts];
        } else {
          // posts data is not an array
          console.error('ERROR: post data is not an array.')
        }
        listings_loading.value = false;
      } else {
        // no data
        listings_loading.value = false;
      }
    };



    watch(
      () => props.prp_ofCategory,
      () => {
        // A new category is being loaded, clear existing data and start a fresh query
        if(props.prp_ofCategory === "sold" || props.prp_ofCategory === "active") {
          listings.value = [];
          posts.value = {};
          runQuery();
        } else {
          // category matches neither sold or active..
          // do nothing..
          console.error('ERROR: category matches neither sold or active..')
        }
      },
    );

    const listing_classes = computed(() => {
      if (listings.value.length <= 3) {
        return 'card_view';
      } else {
        return props.prp_classes;
      }
    });

    const noCurrentListings = computed(() => {
      if(listings.value.length === 0 && !listings_loading.value) {
        return true;
      } else {
        return false;
      }
    });

    const updateLoading = (theBool) =>  {
      listings_loading.value = theBool;
    }

    runQuery();

    return {
      listings_loading,
      posts,
      listings,
      needsPagination,
      ofCategory,
      gql_query,
      listing_classes,
      runQuery,
      handlePosts,
      noCurrentListings,
      updateLoading,
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import '@/scss/_global.scss';
  @import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Varela+Round&display=swap');
  
  .loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    animation: spin 2s linear infinite;
  }

  .load_icon_container {
    position: relative;
    display: inline-block;
    width: 100%;
    height: auto;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

/* ---------------------------
  ----- universal rack styles
  ------------------------------*/
 

  .listing_rack {
    display: inline-block;
    width: 100%;
    margin: 0 auto;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0 auto;
    }
    .listing { cursor: pointer; }

    p.listings__none { margin: 0 auto; }
  }


  /* ---------------------------
  ----- card style rack
  ------------------------------*/
 
 .card_view {
  .listing__rack {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 1em auto;
    padding: 0 1.5%;
  }

  li {
    display: inline-block;
    margin:0 1.5% 3% 1.5%;
    padding: 0;
    box-sizing: border-box;
    width: 30%;
  }

  @media only screen and (max-width: 830px) {
      li { width: 47% }
  }
  @media only screen and (max-width: 570px) {
      li { width: 100% }
  }
 }
  
  hr {
    border: 0;
    height: 1px;
    background: #fff;
    width: 2.65em;
    margin: 1em auto;
  } // FRONT PAGE RACK END

 /* ---------------------------
  ----- grid style rack
  ------------------------------*/
 
 .grid_view {
  .listing__rack {
    display: grid;
    grid-gap: 0;
    width: 100%;
    margin: 1em auto;
    grid-template-columns: repeat(4, 1fr);
  }

  figure {
    padding: 10%;
    background: rgba(0, 0, 0, 0.8);
    height: 100%;
    opacity: 0;
    transform: scale(0.9);
    filter: blur(0px);

  }

  figure:hover { 
    opacity: 1; 
    transform: scale(1);  
    filter: blur(0px);
  }

  hr {
    border: 0;
    height: 1px;
    background: #fff;
    width: 2.65em;
    margin: 1em auto;
  }

  @media only screen and (max-width: 1215px) {
    .listing__rack {
    grid-template-columns: repeat(3, 1fr);
    }   
  }
  @media only screen and (max-width: 770px) {
    .listing__rack {
    grid-template-columns: repeat(2, 1fr);
    }   
  }
  @media only screen and (max-width: 570px) {
    .listing__rack {
      grid-template-columns: repeat(1, 1fr);
    }
  }
 } // <--- GRID RACK END
 

</style>
