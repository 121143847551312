<template>
  <div :id="id"
  :class="prp_classes"
  class="listing"
  :style="listingBgStyle"
  >
   <figure>
     <div v-if="prp_image_path" class="listing_image" :style="featuredImageBgStyle">
       <div v-if="prp_in_category" class="flag" aria-label="listing status" :class="prp_in_category">SOLD</div>
       <img :src="prp_image_path" alt="an image of the home featured in the listing">
     </div>
     <div class="listing_body">
       <h2 v-if="prp_title">{{ formatTitleCase(prp_title) }}</h2>
       <hr v-if="prp_home_address || prp_price || prp_square_feet || prp_other_data">
       <table>
         <tbody>
           <tr v-if="prp_home_address">
             <td class="cell-icon"><MdPinIcon class="icon_svg" /></td>
             <td><address v-html="prp_home_address"></address></td>
           </tr>
           <tr v-if="prp_price">
             <td class="cell-icon"><MdCashIcon class="icon_svg" /></td>
             <td>${{formatPrice}}</td>
           </tr>
           <tr v-if="prp_square_feet">
             <td class="cell-icon"><MdGridIcon class="icon_svg" /></td>
             <td>{{prp_square_feet}} Sq Ft</td>
           </tr>
           <tr v-if="prp_other_data" class="other_data">
             <td class="cell-icon"><MdJournalIcon class="icon_svg" /></td>
             <td v-html="prp_other_data"></td>
           </tr>
         </tbody>
       </table>
     </div>
   </figure>
 </div>
</template>

<script>
import { ref, computed, onMounted, watch, defineComponent } from 'vue';
import MdPinIcon from 'vue-ionicons/dist/md-pin.vue';
import MdGridIcon from 'vue-ionicons/dist/md-grid.vue';
import MdCashIcon from 'vue-ionicons/dist/md-cash.vue';
import MdJournalIcon from 'vue-ionicons/dist/md-journal.vue';

export default defineComponent({
 name: 'Listing',
 components: {
   MdPinIcon,
   MdGridIcon,
   MdCashIcon,
   MdJournalIcon
 },
 props: {
   id: String,
   prp_classes: String,
   prp_home_address: String,
   prp_price: Number,
   prp_square_feet: Number,
   prp_other_data: String,
   prp_image_path: String,
   prp_title: String,
   prp_in_category: String
 },
 setup(props) {
   const featuredImageBgStyle = ref('');
   const listingBgStyle = ref('');

   const formatTitleCase = (toTransform) => {
     return toTransform.replace(/(\w)(\w*)/g, (_, firstChar, rest) => firstChar + rest.toLowerCase());
   };

   const checkClasses = () => {
      // add a featured image to the various types of listing display modes
     if (props.prp_classes === 'grid_view' && props.prp_image_path) {
       listingBgStyle.value = 'background: center / cover no-repeat url(' + imagePath.value + ');';
       featuredImageBgStyle.value = 'background-image: none;';
     } else if (props.prp_classes === 'post_view' && imagePath.value) {
       listingBgStyle.value = 'background-image: none;';
       featuredImageBgStyle.value = 'background: center / cover no-repeat url(' + imagePath.value + ');';
      } else {
        featuredImageBgStyle.value = 'background-image: none;';
        listingBgStyle.value = 'background-image: none;';
      }
    };

    const formatPrice = computed(() => {
      if (!props.prp_price) return;
      return Number(parseFloat(props.prp_price).toFixed(2)).toLocaleString('en');
    });

    const imagePath = computed(() => {
      // swap extention for webp.. 
      // code not working well, may need to revisit this..
      let imgPath;
      if (props.$imgExt === 'webp') {
        if (props.prp_image_path.includes('.jpg')) {
          imgPath = props.prp_image_path.replace('.jpg', '.jpg.webp');
        } else if (props.prp_image_path.includes('.jpeg')) {
          imgPath = props.prp_image_path.replace('.jpeg', '.jpeg.webp');
        } else if (props.prp_image_path.includes('.png')) {
          imgPath = props.prp_image_path.replace('.png', '.png.webp');
        } else {
          imgPath = props.prp_image_path;
        }
      }

      if(!imgPath) {
        imgPath = props.prp_image_path;
      }

      return imgPath;
    });

    onMounted(() => {
      checkClasses();
    });

    watch(() => props.prp_classes, () => {
      checkClasses();
    });

    return {
      featuredImageBgStyle,
      listingBgStyle,
      formatTitleCase,
      formatPrice,
      imagePath,
      checkClasses
    };
  },
});
</script>
<style lang="scss" scoped>
  @import '@/scss/_global.scss';

  .flag {
    visibility: hidden;
    background: $color_primary;
    position: absolute;
    width: 400px;
    padding: 0.65em 0;
    transform: rotate(-45deg) translate(-31%, -237%);
    font-weight: 800;
    letter-spacing: 0.1em;
    font-size: 1em;
  }

  .flag.sold {
    visibility: hidden; /* May 31, 2023 - removing until I can sort out what to do with the 'homes sold' section which is now 'current show home' */
  }

  /* 
  --- DEFAULT VIEW (card view) ---------------------
  */

.listing.listing__featured  { background-color: $color_primary; }

.listing {
  display: inline-block;
  background-image: none;
  background-color: $color__background--dark;
  width: 100%;
  height: 100%;
  color: white;
  text-align: center;
  box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.25);
  fill: white;
  padding: 0 0 2.5em 0;
  
  table {
    margin: 0 auto;
    padding: 0 3em;
    width: auto;
    max-width: 350px;
  }
  h2 {
    font-family: $font-secondary, sans-serif;
    font-weight: 600;
    font-size: 1.8em;
    font-style: normal;
    margin-top: 1em;
    padding: 0 1em;
    text-transform: capitalize;
    text-align: center;
  }
  ul {
    list-style-type: none;
    padding: 0;
    width: 200px;
    margin: 0 auto;
    text-align: left;
  }

  
  
  ::v-deep tr.other_data {

     ul {
      list-style-type: disc; 
      list-style-position: inside;
      padding: 0;
      margin: 0;
      margin-left: 0.5em;  

      li {
        display: list-item;
        width: auto;
        margin: 0;
        padding: 0;
      }
    }
  }

  td {
    font-size: 0.9em;
    text-align: left;
    padding: 0.4em 0;
  }
  tr:not(:last-child) td:not(:first-child) {
    border-bottom: 1px dotted rgba(255,255,255, 0.2);
  }
  div.listing_image {
    overflow: hidden;
    position: relative;
  }

  .cell-icon { 
    padding: 0 0.75em 0 0; 
  }
}

/* 
 --- POST VIEW ---------------------
*/

.post_view {
  
  padding: 0;
  height: auto;

  figure {
    display: flex;
    margin: 0;
    align-items: stretch;
    height: auto;
  }
  figure img {
    display: none;
  }
  div.listing_image {
    display: block;
    max-width: 50%;
    min-height: 400px;
    width: 100%;
  }
  div.listing_body {
    width: 100%;
    padding-bottom: 4em;
  }
  h2 {
    margin-top: 8%;
    font-size: 2.75em;
  }
  table {
    width: 100%;
    max-width: 77%;
    padding-bottom: 5%;
  }
  td {
      font-size: 1.3em;
      fill: white;
    }
  td.cell-icon { text-align: right; }
  hr {
      margin: 2em auto;
  }

  @media only screen and (max-width: 1000px) {
      h2 { font-size: 2em; }
      td { font-size: 1em; }
    
  }

  @media only screen and (max-width: 781px) {
    h2 { font-size: 2em; }
    td { font-size: 1em; }
    
    figure {
      display: block;
      margin: 0;
      height: auto;
    }
    div.listing_image {
      display: block;
      width: 100%;
      max-width: 100%;
      margin: 0 auto;
    }
  }
}

/* 
  --- GRID VIEW ---------------------
*/
.grid_view {
  .listing {
    background-size: cover;
    padding: 0;
    img {
      display: none;
    }
    table {
      margin: 0 auto;
      padding: 0;
      width: auto;
    }
    h2 {
      font-family: $font-secondary, sans-serif;
      font-weight: 600;
      font-size: 1.5em;
      font-style: normal;
      margin-top: 1em;
      padding: 0 1em;
    }
    ul {
      width: 200px;
      text-align: left;
    }
    td {
      font-size: 0.9em;
      text-align: left;
      padding: 0.4em 0;
    }
    tr:not(:last-child) td:not(:first-child) {
      border-bottom: 1px dotted rgba(255,255,255, 0.6);
    }

    .cell-icon { 
      padding: 0 0.75em 0 0; 
    }
  }
}

</style>