<template>
  <main>
    <section id="section__primary">
    <Loading_Spinner v-if="loadingSpinner" />
    <Listing v-if="postData.listings" 
        :prp_home_address="postData.listings.homeaddress"
        :prp_price="postData.listings.price"
        :prp_square_feet="postData.listings.squarefeet"
        :prp_other_data="postData.listings.otherdata"
        :prp_image_path="postData.featuredImage.node.sourceUrl" 
        :prp_title="postData.title"
        prp_classes="post_view"
        :prp_in_category="postData.categories.nodes[0].name"
    />
    <!-- if post has content display it here.. -->
    <article v-if="postData.content" v-html="postData.content"></article>
    </section>
  </main>
</template>

<script>
import {defineComponent, ref, watch, watchEffect } from "vue";
import { useQuery } from '@vue/apollo-composable';
import gql from "graphql-tag";
import Listing from "./Listing.vue";
import Loading_Spinner from "./Loading_Spinner.vue";

const gql_query = gql`
  query ($postId: Int!) {
    postBy(postId: $postId) {
      listings {
        condofee
        homeaddress
        otherdata
        price
        squarefeet
      }
      title
      content(format: RENDERED)
      categories {
        nodes {
          categoryId
          name
        }
      }
      featuredImage {
        node {
          sourceUrl
        }
      }
    }
  }
`;

export default defineComponent({
  name: "Page__Listing",
  props: ["post_id"],
  components: {
    Listing,
    Loading_Spinner,
  },
  setup(props) {
    const loadingSpinner = ref(true);
    const postData = ref([]);

    const runQuery = () => {
      return new Promise((resolve, reject) => {
        const { postId } = props;
        const { result, loading, error, refetch } = useQuery(
          gql_query,
          {
            postId: parseInt(props.post_id),
          },
          { fetchPolicy: 'network-only' }
        );

        watchEffect(() => {
          if (!error.value && result.value) {
            let theListing = result.value.postBy;

            console.log('>> [Page__Listing] content values = ',theListing);

            if (!theListing.featuredImage) {
              theListing.featuredImage = {
                node: {
                  sourceUrl: 'https://stewartelston.dreamhosters.com/wp-content/uploads/2021/06/no-house-image.jpg',
                },
              };
            }

            postData.value = theListing;
            loadingSpinner.value = false;
            resolve();
          } else if (error.value) {
            loadingSpinner.value = false;
            reject(error.value);
          }
        });
        refetch();
      });
    };

    // make sure we're getting a proper postId (not null)
    if (props.post_id) {
      runQuery();
    } else {
      console.error('ERROR: problem with post_id (null)..')
      // problem with the post_id
      // doing nothing..
    }

    return {
      loadingSpinner,
      postData,
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/scss/_global.scss';

  
  .ionicon__secondary {
    display:inline-block;
    color: white;
  }
  .icon_svg {
    display: inline;
    fill:white;
  }

  section {
    margin: 0 auto;
    position: relative;
    width: 90%;
    height: auto;
  }
  article {
    min-height: 450px;
  }

  // The ::v-deep selector in Vue is a deep selector that allows styles to be applied deeply into child components, bypassing Vue's scoped CSS encapsulation and affecting nested component's styles.
  ::v-deep .wp-block-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 1em;
  }

  ::v-deep .wp-block-gallery .wp-block-image img {
    width: 100%;
    object-fit: cover; /* crop the image to fill the grid cell, preserving its aspect ratio */
    height: 100%;
  }
  
  hr {
    border: 0;
    height: 1px;
    background: #fff;
    width: 2.65em;
    margin: 0.8em auto;
  }

  section#section__primary {
    background: $color__background--light;
    color: #999;
    min-height: 400px;
    p {
        font-size: 1.1em;
        margin-left: 8%;
        margin-bottom: 2em;
        max-width: 400px;
        
    }
    h2 {
      font-weight: 400;
      font-size: 2em;
      line-height: 1.2em;
      margin: 8% 0 0.75em 8%;
    }
    .row {
      padding: 200px 0 10% 0;
    }
  } 
  @media only screen and (max-width: 781px) {
    section {
      width: 100%;
    }
    main {
      margin-top: 1.5rem;
    }
  } 
</style>
