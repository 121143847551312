<template>
  <main>
    <section id="section__hero">
        <img id="headshot-stewart" src="../assets/img/headshotStewart.png" alt="An image of Stewart Elston smiling">
    </section>
    <section id="section__secondary" class="container__grid">
      <aside>
        <picture>
          <source srcset="../assets/img/logo__powell_property_group--light.webp" type="image/webp">
          <img class="logo-partner" width="300" height="112" src="../assets/img/logo__powell_property_group--light.png" alt="Powell Property Group logo">
        </picture>
        <picture>
          <source srcset="../assets/img/logo__irwin_homes--light.webp" type="image/webp">
          <img class="logo-partner" width="420" height="240" src="../assets/img/logo__irwin_homes--light.png" alt="Irwin Homes logo">
        </picture>
        <picture>
          <source srcset="../assets/img/logo__country_oak_estates--light.webp" type="image/webp">
          <img class="logo-partner" width="824" height="288" src="../assets/img/logo__country_oak_estates--light.png" alt="Country Oak Estates logo">
        </picture>
      </aside>
      <!-- current show home post is designated to post ID 891, if this changes, update the value here -->
      <router-link :to="{ name: 'Listing', params: { post_id: 891 } }">
        <transition name="fade" appear>
          <Listing v-if="showHomeDataExists" id="showhome_current" class="listing__featured card_view" 
            :prp_home_address="showHomeData.post.listings?.homeaddress"
            :prp_price="showHomeData.post.listings?.price"
            :prp_square_feet="showHomeData.post.listings?.squarefeet"
            :prp_other_data="showHomeData.post.listings?.otherdata"
            :prp_title="showHomeData.post.title"
          />
        </transition>
      </router-link>
      <div class="row">
        <div class="col-1"></div>
        <div class="col-2"><h2>For all your<br>real estate needs</h2></div>
        <div class="col-8"><p>With 25 years experience in the Winnipeg Real Estate Market, Stewart Elston of the Powell Property Group provides clients with the benefit of his vast knowledge of the resale market, and a level of service that is unparalleled. Stewart was the president of the Winnipeg Regional Real Estate Association (WRREB) in 2016, President of the Manitoba Real Estate Association (MREA) in 2021/2022, and served on the Finance Committee of the Canadian Real Estate Association (CREA) 2023/2024. He has a good deal of experience in the Condo Market, and would be a great choice to assist you in the purchase or sale of a Condo Property. If you&apos;re looking to build a new home, then look no further than Stewart for assistance. He has worked with many of Winnipeg&apos;s leading home builders, and can assist you. Whether you&apos;re buying a pre-owned home, having a new one built or selling your existing property, Stewart Elston is &quot;your Winnipeg expert&quot;.</p></div>
        <div class="col-1"></div>
      </div>
    </section>
    <section id="section__tertiary">
      <h2>Current Show Home</h2>
      <!-- <p>Whether you&apos;re looking to purchase your first home, &quot;stepping up a level&quot;, or acquiring your &quot;dream home&quot;, Stewart can make it happen. Here are the most recent homes that I have sold.</p> -->
      <ListingRack 
        class="card_view" 
        prp_ofCategory="sold" 
      />
    </section>
  </main>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import Listing from './Listing.vue';
import ListingRack from './Listing__Rack.vue';
import gql from 'graphql-tag';
import { useRoute } from 'vue-router';

const gql_query = gql`query  {
  post(id: "891", idType: DATABASE_ID) {
    id
    listings {
      condofee
      homeaddress
      otherdata
      price
      squarefeet
    }
    title
    featuredImage {
      node {
        sourceUrl
      }
    }
  }
}
`;

export default defineComponent({
  name: 'Front_Page__Panel',
  components: {
    Listing,
    ListingRack,
  },
  setup() {
    const showHomeDataExists = ref(false);
    const showHomeData = ref([]);
    const { result, onError, refetch } = useQuery(gql_query);
    const route = useRoute();

    result.value = data => data.post;

    // if(showHomeData.value.length > 0) {
    //   showHomeDataExists.value = true;
    // } else {
    //   showHomeDataExists.value = false;
    // }

    onError(error => {
      console.log('the error: ', error);
    });

    watch(result, () => {
      if (result.value !== null && result.value !== undefined) {
        // if we have posts in the result show the showhome box
        if(result.value.post) {
          showHomeDataExists.value = true;
        } else {
          showHomeDataExists.value = false;
        }
        // whatever data we have set it
        //showHomeData.value = result.value;

        // if (showHomeData.value.post.listings.otherdata) {
        //   showHomeData.value.post.listings.otherdata = showHomeData.value.post.listings.otherdata+"<br><u>Click here for more photos.</u>"
        // }

        let localShowHomeData = JSON.parse(JSON.stringify(result.value));

        if (localShowHomeData.post && localShowHomeData.post.listings && localShowHomeData.post.listings.otherdata) {
          localShowHomeData.post.listings.otherdata += "<br><u>Click here for more photos.</u>";
        }

        showHomeData.value = localShowHomeData;

      } else {
        // no data came through
        showHomeDataExists.value = false;
      }
    });

    console.log('>> showhome data ',showHomeData.value);

    // if we are on the home page, refetch the show home data
    // watch(() => route.path, (newVal, oldVal) => {
    //   if (newVal === '/' || newVal === '/home') {
    //     console.log('>> [Page__Front](watch/router.path) refetching show home data');
    //     refetch();
    //   }
    // });

    watch(route, (to) => {
      console.log('>> [Page__Front](watch/router.path) route changed');
      if (to.path === '/' || to.path === '/home') {
        console.log('>> [Page__Front](watch/router.path) refetching show home data');
        refetch();
      } 
    });

    return {
      showHomeDataExists,
      showHomeData,
      refetch,
    };
  },
  mounted() {
      // SEO schema.org data
      let structuredData = {
        "@context": "https://schema.org",
        "@type": "ProfessionalService",
        "name": "Stewart Elston",
        "serviceType": "Real Estate Services",
        "provider": {
          "@type": "Person",
          "name": "Stewart Elston"
        },
        "image": "https://stewartelston.dreamhosters.com/wp-content/uploads/2021/06/portraitStewart.jpg",
        "description": "With over 20 years experience in the Winnipeg Real Estate Market, awarded and experienced Stewart Elston of the Powell Property Group provides clients with the benefit of his vast knowledge of the resale market...",
        "areaServed": {
          "@type": "AdministrativeArea",
          "name": "Winnipeg"
        },
        "telephone": "12047819999",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Winnipeg",
          "addressRegion": "MB",
          "postalCode": "R2J 4A4",
          "streetAddress": "79 Southbridge Dr, Winnipeg"
        }
      };

      let scriptElement = document.createElement('script');
      scriptElement.type = 'application/ld+json';
      scriptElement.innerHTML = JSON.stringify(structuredData);
      document.head.appendChild(scriptElement);

       // Refetch data when component is mounted
        console.log('>> [Page__Front](mounted) refetching show home data');
        this.refetch();
    },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/scss/_global.scss';

  .ionicon__secondary {
    display:inline-block;
    color: white;
  }
  .icon_svg {
    display: inline;
    fill:white;
  }
  .container__grid {
      width: 90%;
      padding: 0 5%;
    }
  h2 {
    display: inline-block;
    font-style: normal;
    text-align: left;
    line-height: 1.2em;
  }
  section#section__hero {
    background-image: url('../assets/img/stock_burbs.jpg');
    background-size: cover;
    background-position: -50%;

    img {
      position: absolute;
    }
    img#headshot-stewart {
      bottom: 0;
      max-width: 80%;
      max-height: 97%;
    }
  }
  hr {
    border: 0;
    height: 1px;
    background: #fff;
    width: 2.65em;
    margin: 0.8em auto;
  }

  section#section__secondary {
    background: $color_secondary;
    color: white;
    p {
        font-size: 1.4em;
        text-align: justify;
        margin-top: -0.3em;
        line-height: 2.5rem;
    }
    h2 {
      font-style: italic;
      font-size: 1.4em;
      text-align: right;
      line-height: 1.3em;
      margin:0;
      margin-right:-15px;
      margin-top: -5px;
    }
    aside {
      width: 112.5%;
      background: black;
      margin: 0 -6.25%;
      top: 0px;
      z-index: 0;
      
      img {
        display: inline-block;
        max-height: 2em;
        margin: 20px;
        margin-left: 10px;
        width: auto;
        height: auto;
      }
      img:first-child {
        margin-left: 30px;
      }
    }
    .row {
      padding: 10em 0 15% 0;
    }
  }

  section#section__tertiary {
    background: $color__background--light;
    color: $color__font--dark;
    .row {
      padding: 200px 0 10% 0;
    }
    h2 {
      margin: 90px 0 0.45em 115px;
    }
    p {
      text-align: justify;
      margin-left: 115px;
      max-width: 409px;
      margin-bottom: 4em;
      line-height: 1.45rem;
    }
  }

  #showhome_current {
    display: inline-block;
    margin-top: 0;
    margin: 0;
    top: -170px;
    margin-left: 55%;
    width: 350px;
    z-index: 10;
    position: relative;
    margin-bottom: -170px;
  }

  @media only screen and (max-width: 925px) {
    #showhome_current {
      margin-left: 42%;
    }
  }
  @media only screen and (max-width: 910px) {
    #showhome_current {
      margin-left: 47%;
    }
  }
  @media only screen and (max-width: 830px) {
    section#section__hero {
      background-position: 50% 50%;
    }
    section#section__hero img {
      max-height: 69%;
    }
  }
  @media only screen and (max-width: 781px) {

    .container__grid {
      width: 100%;
    }
    
    section#section__secondary {
      h2 {
        text-align: left;
        margin:0;
      }
      .row {
        text-align: left;
      }
      p {
        font-size: 1.1em; 
        line-height: 1.9rem;
      }
      .row {
        padding: 100px 0 10% 0;
      }
    }
  }
  @media only screen and (max-width: 769px) {
    #showhome_current {
      margin-top: 0;
      margin-left: -6.2%;
      width: 112.5%;
      top: 0;
      margin-bottom: -50px;
    }
    #section__hero {
      min-height: 48vh;
      #headshot-stewart {
        height: 65%;
      }
    }
    section#section__secondary {
      aside {
        display: flex;
        justify-content: space-evenly;
      }
    }
    section#section__tertiary {
      p { 
        margin-left: 12.5%; 
        width: 75%;
      }
      h2 { 
        margin-left: 12.3% ; 
        width: 75%; 
        font-size: 3rem;
        line-height: 3rem;
        margin-bottom: 1.75rem;
      }
    }
  }
  @media only screen and (max-width: 475px) {
    #section__hero img {
      max-height: 262px;
    }
    #section__hero {
      min-height: 38vh;
    }
  }
</style>
