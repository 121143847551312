<template>
  <main>
    <section id="section__primary--about">
      <Loading_Spinner v-if="loading" class="light" />
      <article v-html="pageContent.content" />
    </section>
    <section id="section__form">
      <ContactForm />
    </section>
  </main>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import ContactForm from './Contact.vue';
import Loading_Spinner from './Loading_Spinner.vue';

const gql_query = gql`
  query {
    page(id: "cG9zdDoxMjQ1") {
      content
    }
  }
`;

export default defineComponent({
  name: 'Page__About',
  components: {
    ContactForm,
    Loading_Spinner,
  },
  setup() {
    const loading = ref(true);
    const pageContent = ref({});
    const { result } = useQuery(gql_query);
    
    //result.value = data => data.page;

    watch(
      () => result.value,
      (data) => {
        if (data && data.page) {
          pageContent.value = data.page;
          loading.value = false;
        }
      },
      { immediate: true }
    );

    return {
      loading,
      pageContent,
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import '@/scss/_global.scss';
  
  section#section__primary--about {
    background: $color__background--dark;
    color: white; 

    p { max-width: 100%; }

    ul {
      list-style-type: disclosure-closed;
      padding-left: 2.25em;
    }

    picture {
      width: 50%;
      height: auto;
      float: right;
      margin: 0 0 1em 1em;
    }

    img {
      width: auto;
      height: auto;
    }

    article:after {
      content: "";
      display: table;
      clear: both;
    }

  }

  @media only screen and (max-width: 781px) {
    section#section__primary--about picture {
      display: block;
      width: 100%;
      float: none;
      margin: 0.25em auto;
      padding: -10px;
    }
  }
</style>
