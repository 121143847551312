<template>
  <main>
    <section id="section__form">
      <ContactForm />
    </section>
  </main>
</template>

<script>
import { defineComponent } from 'vue';
import ContactForm from './Contact.vue';
export default defineComponent({
  name: 'Page__Contact',
  components: { ContactForm }
});
</script>

<style lang="scss" scoped>
  @import '@/scss/_global.scss';
  #section__form { background: $color__background--light; }
</style>
