import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import { createHttpLink } from '@apollo/client/link/http';
import { InMemoryCache, ApolloClient } from '@apollo/client/core';
import { provideApolloClient } from '@vue/apollo-composable';
import 'babel-polyfill';

// check for webp support
function webpSupported() {
  const elem = document.createElement('canvas');
  if (elem.getContext && elem.getContext('2d')) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
  } else {
    // very old browser like IE 8, canvas not supported
    return false;
  }
}

const httpLink = createHttpLink({
  // use an absolute URL here
  uri: 'https://stewartelston.dreamhosters.com/graphql',
});

// cache stores client side query results to avoid too many unecessary calls
const cache = new InMemoryCache();

// ApolloClient represents main entry point for interacting with a GraphQL API using Apollo Client. 
// Takes input object containing a cache instance and a link instance & provides methods for executing queries and mutations against the API.
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
});

const app = createApp(App);

// set a global property for image extention to flag that webp is available (or not)
if (webpSupported()) {
  app.config.globalProperties.$imgExt = 'webp';
} else {
  app.config.globalProperties.$imgExt = null;
}

// enable Vue 2-compatible mode
app.config.compatConfig = {
  MODE: 2,
};

app.config.globalProperties.$http = axios;

app.use(router); // use the router

// provide Apollo Client instance to the Vue app using the provide function. 
provideApolloClient(apolloClient);

app.provide('$http', axios); // provide Axios instance

app.mount('#app'); // mount the app
