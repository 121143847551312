<template>
  <footer>
    <small>
    <p>&copy; Copyright {{currentYear}} Stewart Elston</p><span>|</span><p>Site Design & Development: Chad Elston</p>
    <span>|</span><p>This site is protected by reCAPTCHA and the Google</p>
    <a href="https://policies.google.com/privacy">Privacy Policy</a> and
    <a href="https://policies.google.com/terms">Terms of Service</a> apply
    </small>  
  </footer>
</template>
<script>
import { ref } from 'vue';

export default {
  setup() {
    const currentYear = ref(new Date().getFullYear());
    return {
      currentYear,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/scss/_global.scss';

footer {
  width: 90%;
  margin: 0 auto;
  position: relative;
  display: block;
  background: $color_primary;
  color: white;
  padding: 0.5em 1em;
}
small {
  font-size: 0.5em;
}
p {
  display: inline;
  margin: 0; 
  padding:0; 
  line-height: 1em;
  font-size:0.5rem; 
}
span {
  display:inline;
  margin: 0 0.75em;
  font-size:0.5rem;
}
a {
  color: white;
  margin: 0 0.25em;
  font-size: 0.5rem;
}

@media only screen and (max-width: 781px) {
  footer {
    width: 100%;
  }
}
</style>
