<template>
  <div class="form-contact">
    <h2>Contact</h2>
    <p>Please feel free to contact Stewart by completing the form below with any questions, comments, or concerns you may have regarding his services:</p>
    <p v-if="showResponse" class="response" :class="responseClass">{{user_feedback_message}}</p>
    <form v-if="!showResponse"
      @submit.prevent="checkForm"
      novalidate="true"
    >
      <div class="field">
        <p class="form-errors" v-if="errors.length">
          <strong>Please address the following error(s):</strong>
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </p>
        <div class="control">
          <label for="name">Full Name:</label>
          <input id="name" :class="name_class" type="text" v-model="value_name" autocomplete="name" aria-required="true">
        </div>
        <div class="phone">
          <label for="telephone">Phone:</label>
          <input 
            id="telephone"
            :class="phone_class"
            type="tel"
            v-model="value_phone"
            autocomplete="tel"
            @input="formatPhoneNumber"
            placeholder="e.g. (204) 555-5555"
            aria-required="true"
          >
        </div>
        <div class="control">
          <label for="email">Email:</label>
          <input
            id="email"
            :class="email_class" 
            type="email" 
            v-model="value_email"
            autocomplete="email" 
            aria-required="true" 
          >
        </div>
        <div class="control">
          <label for="subject">Subject:</label>
          <input 
            id="subject" 
            :class="subject_class"
            type="text" 
            v-model="value_subject" 
            aria-required="true"
          >
        </div>
        <div class="control">
          <label :class="message_class" for="message">Enter your message for Stewart:</label>
          <textarea 
            id="message" 
            :class="message_class"
            v-model="value_message"  
            aria-required="true"
          ></textarea>
        </div>
        <button type="submit" value="Submit">
          <span :class='submitBtnClass'>Submit</span>
          <Loading_Spinner prp_classes="contain_button" v-if="state != 'collecting'" />
        </button>
        <!-- <div class="g-recaptcha" :data-sitekey="captchaSiteKey"></div> -->
      </div>
    </form>
  </div>
</template>

<script>
  /* global grecaptcha */
  import { defineComponent, ref, watch, onMounted, onUnmounted } from 'vue';
  import Loading_Spinner from './Loading_Spinner.vue'
  import axios from 'axios';
  

  export default defineComponent({
    components: { Loading_Spinner },
    setup() {
      const state = ref('collecting');
      const submitBtnClass = ref('show');
      const captchaSiteKey = '6Lc3IT4bAAAAAMIgFjFQVA5PEXayfhDOkB-w8Twm';
      const responseClass = ref('success');
      const showResponse = ref(false);
      const errors = ref([]);
      const value_name = ref('');
      const value_phone = ref('');
      const value_email = ref('');
      const value_subject = ref('');
      const value_message = ref('');
      const name_class = ref(''); 
      const phone_class = ref('');
      const email_class = ref('');
      const subject_class = ref('');
      const message_class = ref('');
      const bodyFormData = ref({});
      const user_feedback_message = ref('');
      const captchaIsSuccess = ref(false);

      onMounted(() => { showRecaptchaBadge(); });
      onUnmounted(() => { hideRecaptchaBadge(); });

      const checkForm = (e) => {
        if(state.value === 'collecting') {
          state.value = 'validating';
          errors.value = [];
          name_class.value = '';
          email_class.value = '';
          phone_class.value = '';
          subject_class.value = '';
          message_class.value = '';

          if (!value_name.value) {
            errors.value.push('Name required.');
            name_class.value = "error";
          }

          if (!value_email.value) {
            errors.value.push('Valid email required.')
            email_class.value = "error"
          } else if (!validateEmail(value_email.value)) {
            errors.value.push('Valid email required.')
            email_class.value = "error"
          }

          //phone number validation
          if (!value_phone.value) {
            phone_class.value = "error"
            errors.value.push('Valid phone number required.')
          } else if (!validatePhoneNumber(value_phone.value)) {
            phone_class.value = "error"
            errors.value.push('Valid phone number required.')
          }

          // subject validation
          if (!value_subject.value) {
            errors.value.push('Subject required.')
            subject_class.value = "error"
          }

          //message validation
          if (!value_message.value) {
            errors.value.push('Please enter your message..')
            message_class.value = "error"
          }

          if (!errors.value.length) {
            bodyFormData.value = new FormData()
            bodyFormData.value.append( 'your-name', value_name.value )
            bodyFormData.value.append( 'tel-725', value_phone.value )
            bodyFormData.value.append( 'your-email', value_email.value )
            bodyFormData.value.append( 'your-subject', value_subject.value )
            bodyFormData.value.append( 'your-message', value_message.value )

            // we passed client validation! next steps..
            validationSuccess();
            return true;
          } else {
            state.value = 'collecting'
          }
        }
        e.preventDefault();
      }

      const formatPhoneNumber = (value) => {
        let x = value_phone.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        value_phone.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      }

      const validateEmail = (email) => {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      }

      const validatePhoneNumber = (phone) => {
        let re = /^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/;
        return re.test(phone);
      }

     const validationSuccess = () => {
        executeRecaptcha()
          .then((recaptchaResponse) => { verifyRecaptcha(recaptchaResponse); })
          .catch((err) => { console.error('>> [Contact] (validationSuccess) Error executing reCAPTCHA:', err); });
      };

      const showRecaptchaBadge = () => {
        const badge = document.querySelector('.grecaptcha-badge');
        if (badge) { badge.style.display = 'block'; badge.style.visibility = "visible"; }
      };

      const hideRecaptchaBadge = () => {
        const badge = document.querySelector('.grecaptcha-badge');
        if (badge) { badge.style.display = 'none'; badge.style.visibility = "hidden"; }
      };

      const executeRecaptcha = async () => {
        return new Promise((resolve, reject) => {
          const checkRecaptcha = () => {
            if (window.grecaptcha && window.grecaptcha.execute) {
              window.grecaptcha.execute(captchaSiteKey, { action: 'submit' }).then(resolve).catch(reject);
            } else { 
              setTimeout(checkRecaptcha, 100); 
            }
          };
          checkRecaptcha();
        });
      };

      const verifyRecaptcha = (token) => {
        let theFormData = JSON.stringify(Object.fromEntries(bodyFormData.value))
        try {
          axios.post('/.netlify/functions/recaptcha', { token: token, formData: theFormData })
            .then((response) => {
              handleResults(response);
            })
            .catch((err) => {
              handleResults(err);
            });
        } 
        catch (err) {
          console.error('ERROR: problem with recaptcha on the contact form')
        }
      }

      const handleResults = (res) =>{
        // potential statuses:
        // 'mail_sent'
        // 'mail_fail'
        // 'error'
        let responseBody = res.data
        state.value = responseBody.status
        user_feedback_message.value = responseBody.message
      }

      watch(()=>state.value, ()=>{
        switch(state.value) {
            case 'validating':
                showResponse.value = false
                submitBtnClass.value = 'hide'
                break;
            case 'collecting':
                showResponse.value = false
                submitBtnClass.value = 'show'
                break;
            case 'error':
                showResponse.value = true
                responseClass.value = 'error'
                submitBtnClass.value = 'hide'
                break;
            case 'mail_sent':
                showResponse.value = true
                responseClass.value = 'success'
                submitBtnClass.value = 'hide'
                break;
            case 'mail_fail':
                showResponse.value = true
                responseClass.value = 'error'
                submitBtnClass.value = 'hide'
                break;
            default:
                //console.log('>> [Contact] unhandled state change.. ',this.$data.state)
            }
      })
      return {
        state,
        submitBtnClass,
        captchaSiteKey,
        responseClass,
        showResponse,
        errors,
        value_name,
        value_phone,
        value_email,
        value_subject,
        value_message,
        name_class,
        phone_class,
        email_class,
        subject_class,
        message_class,
        user_feedback_message,
        checkForm,
        formatPhoneNumber
      };
    }//setup
  })
</script>
<style lang="scss" scoped>
@import '@/scss/_global.scss';

$input__width__max--short: 325px;
$input__width__max--long: 600px;

.response.success { color: blue; }
.response.error { color: red; }

.form-contact {
  background: $color__background--light;
  color: #666;
  padding: 2em;
  font-weight: 400;

  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 0.4em 0;
  }

  form {
    display: block;
    // margin: 2em auto;
    position: relative;
    max-width: $input__width__max--long;
    width: 100%;

  }

  label {
    display: block;
  }

  input {
    max-width: $input__width__max--short;
  }

  textarea, input#subject, button {
    max-width: $input__width__max--long;
  }

  input, textarea {
    padding: 10px;
    margin: 10px 0; // add top and bottom margin
    padding: 10px;
    border-radius:10px;
    width: 100%;
  }

  input:focus, textarea:focus {
    border-color: blue;
    outline: none;
  }

  input.error, textarea.error {
    border-color: red;
  }

  textarea {
    width: 100%;
    height: 300px;
  }

  .border-customized-input {
    border: 2px solid #eee;
  }

  .form-errors {
    color: red;
    font-weight: bold;
  }

  button {
    position: relative;
    /* remove default behavior */
    appearance:none;
    -webkit-appearance:none;

    /* usual styles */
    padding:10px;
    border:none;
    background-color:#3F51B5;
    color:#fff;
    font-weight:600;
    border-radius:5px;
    width:100%;
  }
}
</style>