<template>
  <main>
    <section id="section__primary--sales">
        <article>
        <Loading_Spinner v-if="loading" class="light" />
            <h2>New Home Sales</h2>
            <div v-html="pageContent.content"></div>
        </article>
    </section>
  </main>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import Loading_Spinner from './Loading_Spinner.vue';

const gql_query = gql`
  query {
    page(id: "cG9zdDoxMjIy") {
      content
    }
  }
`;

export default defineComponent({
  name: 'Page__Sales',
  components: {
    Loading_Spinner,
  },
  setup() {
    const loading = ref(true);
    const pageContent = ref([]);
    const { result } = useQuery(gql_query);

    watch(
      () => result.value,
      (data) => {
        if (data) {
          pageContent.value = data.page;
          loading.value = false;
        }
      },
      { immediate: true }
    );

    return {
      loading,
      pageContent,
    };
  },
});
</script>

<style lang="scss" >
  @import '@/scss/_global.scss';
  
  section#section__primary--sales { 
    background: $color__background--dark; 
    color: white; 
    
    article {
      figure {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin: 0 auto;
        justify-content: center;
        width: 100%;
        margin: 4em 0;
        picture {
          max-width: 30%;
          width: 100%; 
          height: auto;
        }
        img {
          margin: 0.4em;
        }
      }
    }
  }
  @media only screen and (max-width: 770px) {
    section#section__primary--sales { 
    article {
      figure {
        display: block;
        picture {
          max-width: 100%;
          width: 100%; 
          height: auto;
        }
      }
    }
  }
  }
</style>
