<template>
  <!-- v-if="!loading" -->
  <div class="pagination">
    <a 
      v-if="this.posts.pageInfo.hasNextPage" 
      @click="paginate(+1)" 
      class="next"
    >
    <!-- v-if="state === 'querying'" -->
      <span :class='nextBtnClass'>More listings</span>
      <Loading_Spinner prp_classes="contain_button" v-if="state === 'querying'" />
    </a>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import Loading_Spinner from './Loading_Spinner.vue';
import { useQuery } from '@vue/apollo-composable';

export default defineComponent({
  name: 'Paginate',
  components: {
    Loading_Spinner,
  },
  props: {
    posts: {
      type: Object,
    },
    postsQuery: {
      type: Object,
    },
    limit: {
      type: Number,
    },
    where: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const state = ref('waiting');
    const firstStartCursor = props.posts.pageInfo.startCursor;
    const hasNextPage = ref(props.posts.pageInfo.hasNextPage);
    const hasPreviousPage = ref(false);
    const first = ref(null);
    const last = ref(null);
    const startCursor = ref('');
    const endCursor = ref('');
    const nextBtnClass = ref('show');

    async function paginate(i) {
      if (!props.postsQuery) {
        return;
      }
      emit('updateLoading', true);

      if (i < 0) {
        first.value = null;
        last.value = props.limit;
        startCursor.value = props.posts.pageInfo.startCursor;
        endCursor.value = null;
      } else if (i > 0) {
        first.value = props.limit;
        last.value = null;
        startCursor.value = null;
        endCursor.value = props.posts.pageInfo.endCursor;
      }
      state.value = 'querying';
      nextBtnClass.value = 'hide';

      const { result, loading, error, refetch } = useQuery(
          props.postsQuery,
          {
            first: first.value,
            last: last.value,
            endCursor: endCursor.value,
            startCursor: startCursor.value,
            where: props.where,
          },
          { fetchPolicy: 'network-only' }
        );

        watch(()=>result.value, () => {
          if (error.value) {
              console.error('ERROR: problem with the query before emit from paginate.')
            } else if (!loading.value && result.value && result.value.posts) {
              emit('updatePosts', result.value);
              emit('updateLoading', false);

              if (i < 0) {
                hasNextPage.value = true;
                hasPreviousPage.value =
                  firstStartCursor == result.value.posts.pageInfo.startCursor
                    ? false
                    : true;
            } else {
              hasPreviousPage.value = true;
              hasNextPage.value = result.value.posts.pageInfo.hasNextPage;
            }
              state.value = 'waiting';
              nextBtnClass.value = 'show';
            }
        }); //watch end
    }//paginate end

    return {
      state,
      nextBtnClass,
      paginate,
    };
  },
});
</script>
<style scoped>
.icon-loading {
  position: relative;
  height: 1em;
  width: 1em;
  margin: 0 auto;
}
.pagination{
  display: flex;
  margin: 55px auto 0px;
  max-width: 900px;
  padding: 0 10px;
  cursor: pointer;
}
.next{
    position: relative;
    margin: 0 auto;
    padding: 1em;
    border-radius: 15px;
    background-color: #23231A;
    color: white;
    margin-bottom: 4em;
}

</style>