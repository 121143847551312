<template>
  <header>
    <router-link :to="{ name: 'Home' }">
      <transition name="fade" appear>
        <div id="site_logo"><h1 class="logo_stewart"><div id="title__stewart">STEWART</div><div id="title__elston">ELSTON</div></h1><span>POWELL PROPERTY GROUP</span></div>
      </transition>
    </router-link>
    <nav id="nav_primary"  role="navigation" aria-label="Primary Navigation">
       <Loading_Spinner class="loading-spinner" v-if="loading" style="right:300px" />
      <div id="menu__container">
        <input id="menu_state" type="checkbox"/>
        <button 
          id="menu__hamburger" 
          for="main_menu" 
          aria-label="Main menu toggle" 
          aria-expanded="false"
          role="button" 
          v-on:click="menuAccessibility"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <ul id="main_menu" aria-label="Main menu" v-if="menuItemsExist">
          <li v-on:click="menuAccessibility" v-for="item in menu_items" :key="item.databaseId">
            <router-link :to="{ path: '/'+isolateSlug(item.path) } ">{{ item.label }}</router-link>
          </li>
        </ul>
      </div>
    </nav>
    <aside><MdMailIcon class="icon_svg" /><router-link :to="{ path: '/Contact'}">Contact Form</router-link>&nbsp;&nbsp;&nbsp;<MdCallIcon class="icon_svg" /><a href="tel:+12047819999">(204) 781-9999</a></aside>
  </header>
</template>

<script>
import { useQuery } from '@vue/apollo-composable';
import { ref, watch, defineComponent } from 'vue';
import Loading_Spinner from './Loading_Spinner.vue';
import MdCallIcon from 'vue-ionicons/dist/md-call.vue';
import MdMailIcon from 'vue-ionicons/dist/md-mail.vue';
import gql from 'graphql-tag';

const gql_query = gql`
  query {
    menu(id: "dGVybTo1") {
      menuItems {
        nodes {
          label
          databaseId
          path
        }
      }
    }
  }
`;

export default defineComponent({
  name: 'HeaderPrimary',
  components: {
    Loading_Spinner,
    MdCallIcon,
    MdMailIcon,
  },
  setup() {
    const { result, loading } = useQuery(gql_query);

    const menu_items = ref([]);
    const menuItemsExist = ref(false);

    const receiveMenuData = (data) => {
      menu_items.value = [...data.menuItems.nodes];
      loading.value = false;
      menuItemsExist.value = true;
    }

    watch(result, (newValue) => {
      if (newValue && newValue.menu) {
        receiveMenuData(newValue.menu);
      }
    });

    const isolateSlug = (thePath) => {
      var pathComponents = thePath.match(/([^/]+)/g);
      var theSlug = pathComponents[pathComponents.length - 1];
      if (theSlug === 'stewartelston') theSlug = '';
      return theSlug;
    };

    const menuAccessibility = (event) => {
      var menu = document.querySelector("#menu_state");
      var menuOpen = menu.checked;

      if (menuOpen) {
        menu.checked = false;
        document.getElementById("menu__hamburger").style.position = "absolute";
      } else {
        menu.checked = true;
        document.getElementById("menu__hamburger").style.position = "fixed";
      }
    };

    return {
      menu_items,
      loading,
      menuItemsExist,
      isolateSlug,
      menuAccessibility,
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/scss/_global.scss';
  @import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Varela+Round&display=swap');

  .icon_svg {
    fill: $color_primary; 
    display: inline-block;
    font-size: 0.9em;
    top: 3px;
    margin-right: 5px;
    display: inline-block;
    position: relative;
    margin-left: 2em;
  }
  .logo_stewart { font-family: 'Lato'}
  #title__stewart {
    letter-spacing: 0.04em;
    border-bottom: 1px solid white;
  }
  #title__elston {
    letter-spacing: 0.2em;
  }
  aside {
    width: auto;
    display: block;
    // margin-left: 6%;
    position: absolute;
    top: 11em;
    right: 5%;
    a {
      color: $color_primary;
      text-decoration: none;
      font-weight: 700;
      font-size: 0.79em;
      position: relative;
      display: inline-block;
    }
  }
  div#header_spacer {
    width: 100%;
    height: 13em;
  }
  h1, nav {
    display: inline-block;
    color: black;
  }
  nav {
    margin-right: 4.4%;
    right: 0;
    float: right;
    position: relative;
    height: 7.75em;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 73%;
    min-width: 500px;
  }
  #site_logo {
    margin-left: 6%;
    display: inline-block;
    width: 165px;

    span {
      display: inline-block;
      font-size: 0.79rem;
      font-weight: 700;
      margin: 0;
      padding: 0;
      width: 12.5rem;
      color: $color_primary
    }
  }

  h1 {
    color: white;
    font-size: 1.7rem;
    background: $color_primary;
    padding: 2rem;
    margin-bottom: 0.1em;
  }
  header {
    background: white;
    position: relative;
    width: 100%;
    height: 13em;
  }
  ul {
    list-style-type: none;
    font-size: 1.2em;
    padding: 0;
    margin: 0;
    text-align: right;
  }
  li {
    display: inline-block;
    margin-top: 1em;
  }
  li:after {
    clear: both;
  }
  li a {
    margin: 0 0 0 1em;
    padding: 0.7em 0.8em;
    box-sizing: border-box;
  }
  li a:hover {
    box-shadow:0px 0px 0px 1px black inset;
    box-sizing: border-box !important;
  }
  li a.is-active {
    box-shadow:0px 0px 0px 1px $color_primary;
    box-sizing: border-box !important;
    color: $color_primary;
  }
  ul li a {
    color: black;
    text-decoration: none;
    
    // font-family: 'DM Sans', sans-serif;
    font-family: 'Varela Round', sans-serif;
    text-transform: uppercase;
    font-size: 0.8em;

  }

  #menu__hamburger, #menu__container input { display: none };
  
  @media only screen and (max-width: 1068px) {
    nav { max-width: 60%; }
  }
  @media only screen and (max-width: 781px) {

  .loading-spinner { display: none; }

    header {
      aside {
        top: 12.4em;
        right: none;
        left: 50%;
        transform: translateX(-50%);
        width: 18rem;
      }
    }

    button {
      border: none; 
      background-color: transparent;
      padding: 0;
      cursor: pointer;
    }
    nav { 
      margin: 0;
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
      height: 0;
      min-width: 0;
      max-width: 100%;
    }

    #menu__container{
      position: absolute;
      z-index: 102;
      width: 100%;

      input {
        right: 37px;
        top: 30px;
        display: none;
        width: 33px;
        height: 21px;
        margin: 0;
        position: absolute;
        cursor: pointer;
        
        opacity: 0; /* hide this */
        z-index: 103; /* and place it over the hamburger */
        
        -webkit-touch-callout: none;
      }
    }

    #menu__hamburger>span{
      display: block;
      width: 33px;
      height: 4px;
      margin-bottom: 5px;
      position: relative;
      
      background: $color_primary;
      border-radius: 3px;
      
      z-index: 100;
      
      transform-origin: 4px 0px;
      
      transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  opacity 0.55s ease;
    }

    #menu__hamburger>span:first-child {
      transform-origin: 0% 0%;
    }

    #menu__hamburger>span:nth-last-child(2) {
      transform-origin: 0% 100%;
    }

    input:checked ~ #menu__hamburger>span {
      opacity: 1;
      transform: rotate(45deg) translate(-9px, -17px);
      background: #232323;
    }

    input:checked ~ #menu__hamburger>span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    input:checked ~ #menu__hamburger>span:nth-last-child(2) {
      transform: rotate(-45deg) translate(-3px, 12px);
    }

    input:checked ~ ul {
      visibility: visible;
      opacity: 1;
      transition: visibility 0.5s, opacity 0.5s linear;
    }

    #menu__hamburger {
      display: block;
      width: 33px;
      height: 33px;
      right: 37px;
      top: 30px;
      position: absolute;
      z-index: 100;
    }
     
    ul {
      visibility: hidden;
      opacity: 0;
      position: fixed;
      width: 100%;
      font-size: 1.3em;
      margin: 0 auto;
      padding: 0;
      background: white;
      top: 0;
      height: 100vh;
      padding-top: 10%;
    }
    li {
      display: block;
      margin: 2em 0;
      text-align: center;
      float: none;
    }

    #site_logo {
      position: relative;
      color: white;
      display: block;
      margin: 0 auto;  
    }
  }
</style>
